import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor() { }

  opened = JSON.parse(this.get('sidebarOpened')) ? true : false;
  sidebarOpened = new BehaviorSubject<boolean>(this.opened);
  sidebarOpenedSubs = this.sidebarOpened.asObservable();

  sidenavToggle() {
    this.opened = !this.opened;
    this.sidebarOpened.next(this.opened);
    this.set('sidebarOpened', `${this.opened}`);
  }

  set(key, value) {
    return localStorage.setItem(key, value);
  }

  remove(key) {
    return localStorage.removeItem(key);
  }

  get(key) {
    return localStorage.getItem(key);
  }
}
