import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RouteGuard } from './shared/route.guard';

import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'sbp', pathMatch: 'full' },
  // { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'login', children: [
      { path: '', component: LoginComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
      { path: '**', redirectTo: '' },
    ]
  },
  { path: 'sbp', loadChildren: () => import('./core/core.module').then(m => m.CoreModule), canActivate: [RouteGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// const routes: Routes = [
//   { path: '', redirectTo: 'sbp', pathMatch: 'full' },
//   // { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
//   { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
//   // { path: 'sbp', loadChildren: './core/core.module#CoreModule', canActivate: [RouteGuard] },
//   { path: 'sbp', loadChildren: () => import('./core/core.module').then(m => m.CoreModule), canActivate: [RouteGuard] },
//   { path: '**', redirectTo: 'login', pathMatch: 'full' }
// ];

// const routes: Routes = [
//   { path: '', redirectTo: 'sbp', pathMatch: 'full' },
//   {
//     path: 'login', children: [
//       { path: '', component: LoginComponent },
//       { path: 'resetpassword', component: ResetPasswordComponent },
//       { path: '**', redirectTo: '' },
//     ]
//   },
//   { path: 'sbp', loadChildren: () => import('./core/core.module').then(m => m.CoreModule), canActivate: [RouteGuard] },
//   { path: '**', redirectTo: 'login', pathMatch: 'full' }
// ];

