import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { User } from '../auth/user.interface';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './shared.service';

const api = environment.apiUrl + '/user';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) { }

  logged = new BehaviorSubject<boolean>(false);
  loggedSubs = this.logged.asObservable();
  user: User;

  checkIsLogged() {
    const token = this.sharedService.get('sbp-token');

    if (token) {
      this.httpClient.get(api + '/login').subscribe(
        (user: User) => {
          this.user = user;
          this.logged.next(true);
        });
    } else {
      // this.router.navigateByUrl('login');
      // this.router.navigate(['/login']);

    }
  }

  login(authData) {
    this.httpClient.post<User>(api + '/login', authData)
      .subscribe(res => {
        this.sharedService.set('sbp-token', res.token);
        this.logged.next(true);
        const url = sessionStorage.getItem('url');
        this.router.navigateByUrl(url);
      });
  }

  logout() {
    this.httpClient.post(api + '/logout', null).subscribe(
      () => {
        this.logged.next(false);
        this.sharedService.remove('sbp-token');
        this.router.navigate(['/login']);
      }
    );
  }

}
