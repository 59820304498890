import { AbstractControl } from '@angular/forms';

export class PasswordValidation {

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value;
    if (AC.get('confirm').touched || AC.get('confirm').dirty) {
      const verifyPassword = AC.get('confirm').value;

      if (password !== verifyPassword) {
        AC.get('confirm').setErrors({ MatchPassword: true });
      } else {
        return null;
      }
    }
  }
}
