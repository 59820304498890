import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';

import { User } from '../user.interface';
import { PasswordValidation } from './password-validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private location: Location, private formBuilder: FormBuilder) { }

  forgotPassword: FormGroup;
  resetPassword: FormGroup;
  user: User;
  firstScreen = true;
  success = false;
  hide = true;

  ngOnInit() {
    this.forgotPassword = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
    });
    // this.resetPassword = new FormGroup({
    //   password: new FormControl('', [Validators.required]),
    //   confirm: new FormControl('', [Validators.required, PasswordValidation.MatchPassword]),
    // });
    this.resetPassword = this.formBuilder.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required]
    }, {
        validator: PasswordValidation.MatchPassword
      });
  }

  onResetPassword() {
    this.firstScreen = false;
  }

  onConfirm() {
    this.success = true;
  }

  goBack() {
    this.location.back();
  }

  toogle() {
  }
}
